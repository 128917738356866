import React, { Component } from 'react';

class FourOhFour extends Component {
    render() {
        return (
            <div>
                Sorry, that page can't be found <span role="img" aria-label="cry">😥</span>
            </div>
        );
    }
}

export default FourOhFour;